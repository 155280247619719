import React from "react";
import { useContext } from "react";
import { InputContext } from "../../../contexts";
import { Form, Row, Col } from "react-bootstrap";
import RevisionInfo from "../RevisionInfo";

const BooleanInput = ({
  fieldData,
  onChangeHandler,
  errors,
  field,
  displayItem,
  readonly,
  labelSubtext,
  inputName,
  fieldID,
}) => {
  // Get our inputContext
  const { inputContext } = useContext(InputContext);

  // Determine whether this field should be displayed normally or inline
  const displayInline =
    inputContext == "inline" ||
    inputContext == "table" ||
    inputContext == "subform";

  const tableOrSubformField =
    inputContext == "table" || inputContext == "subform";

  const fieldName = inputName;

  // Build our form input
  const formControl = () => {
    let checkboxProps = {
      checked: fieldData.value || false,
      onChange: (e) => {
        onChangeHandler(e);
      },
      isInvalid: errors.length > 0,
      label: displayItem.hideLabel ? null : displayItem.label,
    };

    if (readonly) {
      checkboxProps["disabled"] = "disabled";
      checkboxProps["onChange"] = (e) => {
        e.preventDefault();
      };
      checkboxProps["className"] = "noClick disabled-show-label";
    }

    let hiddenProps = {
      name: fieldName,
      value: fieldData.value || false,
      hidden: true,
      readOnly: true,
    };

    return (
      <>
        <div className="d-flex align-items-center">
          <Form.Check {...checkboxProps} id={fieldName} label={null} />
          {!displayItem.hideLabel && (
            <label htmlFor={fieldName} className="custom-line-height ms-2">
              {displayItem.label}
            </label>
          )}
        </div>
        <Form.Control {...hiddenProps} />
      </>
    );
  };

  // Build our error messages
  const errorFeedback = (
    <Form.Control.Feedback className="error-message" type="invalid">
      {errors.join(", ")}
    </Form.Control.Feedback>
  );

  if (displayInline) {
    return (
      <Col sm={"auto"}>
        <Form.Group
          controlId={fieldName}
          as={Row}
          className={`${field.hint ? "" : "mb-3"} align-items-center`}
          id={fieldID}
        >
          <Col sm={"auto"}>
            <div className={errors.length > 0 ? "is-invalid" : null}>
              {formControl()}
            </div>
            <RevisionInfo fieldData={fieldData}></RevisionInfo>
            {errorFeedback}
          </Col>
          {field.hint && (
            <div
              className={`field-hint custom-line-height field-hint-${
                field.identifier
              } ${
                tableOrSubformField ? "hint-table-subform-context" : ""
              } mb-3 text-muted fst-italic`}
            >
              {field.hint}
            </div>
          )}
        </Form.Group>
      </Col>
    );
  } else {
    return (
      <Form.Group
        controlId={fieldName}
        as={Row}
        className={`${field.hint ? "" : "mb-3"} align-items-center`}
        id={fieldID}
      >
        <Col sm={5}></Col>
        <Col sm={7}>
          <div className={errors.length > 0 ? "is-invalid" : null}>
            {formControl()}
          </div>
          <RevisionInfo fieldData={fieldData}></RevisionInfo>
          {errorFeedback}
        </Col>
        {field.hint && (
          <div
            className={`field-hint custom-line-height field-hint-${
              field.identifier
            } ${
              tableOrSubformField ? "hint-table-subform-context" : ""
            } mb-3 text-muted fst-italic`}
          >
            {field.hint}
          </div>
        )}
      </Form.Group>
    );
  }
};

export default BooleanInput;
