import React from "react";
import { useContext } from "react";
import { InputContext } from "../../../contexts";
import { Form, Row, Col } from "react-bootstrap";
import ClearSelection from "./ClearSelection";
import RevisionInfo from "../RevisionInfo";

// NOTE: this file handles both checkboxes and radios - does not handle selects or booleans
const CheckInput = ({
  fieldData,
  onChangeHandler,
  clearSelectionHandler,
  errors,
  field,
  displayItem,
  readonly,
  labelSubtext,
  options,
  inputName,
  fieldID,
}) => {
  // Get our inputContext
  const { inputContext } = useContext(InputContext);

  // Determine whether this field should be displayed normally or inline
  const displayInline = inputContext == "inline" || inputContext == "table";

  const tableOrSubformField =
    inputContext == "table" || inputContext == "subform";

  let inputType;
  let horizontal = false;
  let fieldName;

  // Check if our label length is less than or equal to 10
  // Used to determine whether to display horizontally/vertically
  const labelLengthLessThanTen =
    options.mapping
      .map((option) => {
        return option.label;
      })
      .join().length <= 10;

  switch (displayItem.widgetType) {
    case "enumRadio":
    case "radioHorizontal":
    case "radioVertical":
      // This is an enum

      if (displayItem.widgetType == "radioHorizontal") {
        horizontal = true;
      } else if (
        displayItem.widgetType == "enumRadio" &&
        labelLengthLessThanTen
      ) {
        horizontal = true;
      }

      fieldName = inputName;

      inputType = "radio";

      break;
    case "checkbox":
    case "checkboxes":
    case "checkboxHorizontal":
    case "checkboxVertical":
      // This is a checkbox

      if (displayItem.widgetType == "checkboxHorizontal") {
        horizontal = true;
      } else if (
        displayItem.widgetType == "checkbox" &&
        labelLengthLessThanTen
      ) {
        horizontal = true;
      }

      fieldName = `${inputName}[]`;

      inputType = "checkbox";

      break;
    default:
      return null;
  }

  // Build our form label
  const formLabel = () => {
    if (displayItem.hideLabel) {
      return null;
    }

    let labelProps = {
      column: true,
      sm: displayInline ? "auto" : 5,
      className: `fw-bold ${
        tableOrSubformField ? "custom-line-height" : "lh-sm"
      }`,
    };

    let upperLabel = <div>{displayItem.label}</div>;

    let lowerLabel = labelSubtext ? (
      <div className="fs-10pt fw-normal fst-italic text-muted">
        {labelSubtext}
      </div>
    ) : null;

    return (
      <Form.Label {...labelProps}>
        {upperLabel}
        {lowerLabel}
      </Form.Label>
    );
  };

  // Build our form inputs
  const formControls = () => {
    let overallFormControlProps = {
      type: inputType,
      name: fieldName,
      onChange: (e) => {
        onChangeHandler(e);
      },
      isInvalid: errors.length > 0,
    };

    if (!displayInline) {
      overallFormControlProps["sm"] = 8;
    } else {
      overallFormControlProps["sm"] = "auto";
    }

    if (readonly) {
      overallFormControlProps["disabled"] = "disabled";
      overallFormControlProps["onChange"] = (e) => {
        e.preventDefault();
      };
      overallFormControlProps["className"] = "noClick disabled-show-label";
    }

    if (horizontal) {
      overallFormControlProps["inline"] = true;
    }

    const checkItems = options.mapping.map((option, index) => {
      let elementId = `${fieldID}_${option.value}`;

      const trimmedLabel = option.label.trim();

      let formControlProps = {
        ...overallFormControlProps,
        label: trimmedLabel,
        value: trimmedLabel,
        id: elementId,
        key: index,
      };

      if (inputType == "checkbox") {
        formControlProps["checked"] = fieldData.value.includes(trimmedLabel);
      } else {
        formControlProps["checked"] = fieldData.value == trimmedLabel;
      }

      return <Form.Check {...formControlProps}></Form.Check>;
    });

    return checkItems;
  };

  // Build our error messages
  const errorFeedback = (
    <Form.Control.Feedback className="error-message" type="invalid">
      {errors.join(", ")}
    </Form.Control.Feedback>
  );

  if (displayInline) {
    return (
      <Col sm={"auto"}>
        <Form.Group
          controlId={fieldName}
          as={Row}
          className={`d-flex align-items-baseline ${field.hint ? "" : "mb-3"}`}
          id={fieldID}
        >
          {formLabel()}
          <Col sm={"auto"} className={!tableOrSubformField ? "ps-0" : ""}>
            <Form.Control
              name={fieldName}
              defaultValue=""
              hidden
            ></Form.Control>
            {formControls()}
            <RevisionInfo fieldData={fieldData}></RevisionInfo>
            {inputType == "radio" && !readonly ? (
              <ClearSelection
                handler={clearSelectionHandler}
                fieldData={fieldData}
                tableOrSubformField={tableOrSubformField}
              ></ClearSelection>
            ) : null}
            {errorFeedback}
          </Col>
          {field.hint && (
            <div
              className={`field-hint custom-line-height field-hint-${
                field.identifier
              } ${
                tableOrSubformField ? "hint-table-subform-context" : ""
              } mb-3 text-muted fst-italic`}
            >
              {field.hint}
            </div>
          )}
        </Form.Group>
      </Col>
    );
  } else {
    return (
      <Form.Group
        controlId={fieldName}
        as={Row}
        className={`${field.hint ? "" : "mb-3"} ${
          horizontal ? "align-items-start" : null
        }${horizontal ? " horizontal-form-check" : ""}`}
        id={fieldID}
      >
        {formLabel()}
        <Col className="col-form-label">
          <Form.Control name={fieldName} defaultValue="" hidden></Form.Control>
          <div className={errors.length > 0 ? "is-invalid" : null}>
            {formControls()}
          </div>
          <RevisionInfo fieldData={fieldData}></RevisionInfo>
          {errorFeedback}
        </Col>
        {inputType == "radio" && !readonly ? (
          <Col sm="auto" className="ps-0">
            <ClearSelection
              handler={clearSelectionHandler}
              fieldData={fieldData}
              tableOrSubformField={tableOrSubformField}
            ></ClearSelection>
          </Col>
        ) : null}
        {field.hint && (
          <div
            className={`field-hint custom-line-height field-hint-${
              field.identifier
            } ${
              tableOrSubformField ? "hint-table-subform-context" : ""
            } mb-3 text-muted fst-italic`}
          >
            {field.hint}
          </div>
        )}
      </Form.Group>
    );
  }
};

export default CheckInput;
